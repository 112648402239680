import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./CardDetailPage.css"; // Import styles for the detailed page
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Card,
  Offcanvas,
} from "react-bootstrap";
import logo from "./images/logo.png";
import "./Navbar.css";
import TestimonialPage from "./Testimonial";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import Emailus from "./Emailus";

const CardDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { card, category_id } = location.state; // Access the card data from the previous page
  console.log("ttttt",card, category_id);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);
  const handleBookSession = () => {
    navigate('/book-session', { state: { card, category_id } });
  };
  return (
    <>
      <div
        className="backgroundcard-image"
        style={{ backgroundImage: `url(${card.image})` }}
      >
        <div className="elementor-background-overlay"></div>
        <Navbar expand="lg" variant="dark" className="transparent-navbar">
          <Container>
            <Navbar.Brand href="/">
              <img
                src={logo}
                height="50" // Adjust height based on your requirement
                alt="Vyatra logo"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleShow}
            />
            <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
              <Nav className="ms-auto nav-links">
                <Nav.Link href="/">HOME</Nav.Link>
                <Nav.Link href="/how-it-works">HOW IT WORKS</Nav.Link>
                <Nav.Link href="/contact">CONTACT</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title> <img
              src={logo}
              height="50" // Adjust height based on your requirement
              alt="Vyatra logo"
              className="d-inline-block align-top"
            /></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="flex-column">
              <Nav.Link href="/" onClick={handleClose}>
                HOME
              </Nav.Link>
              <Nav.Link href="/how-it-works" onClick={handleClose}>
                HOW IT WORKS
              </Nav.Link>
              <Nav.Link href="/contact" onClick={handleClose}>
                CONTACT
              </Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>

        <Container className="video">
          <Row>
            <Col md={8} className="CardDetailSubtitle">
              <h1>{card.description === "Jersualem, Israel" ? "Investigating The Ancient Past" :card.description === "The Shogunate Japan" ? "The Asia-Pacific World" : card.subtitle}</h1>
              <p style={{ color: "white" }}>{card.description === "Jersualem, Israel" ?"The Holy Land – Jersualem, Israel":card.description}</p>
            </Col>
            <Col md={4}>
              {/* {card.description === "The Holy Land" ? null : ( */}
                <div className="CardDetailContent">
                  <h4 style={{ color: "white" }}>Price</h4>

                  {/* Only render price details if all fields are present */}

                  <div className="count-num">
                    {card.span1 && <span>{card.span1}</span>}
                    {card.h1 && <h1>{card.h1}</h1>}
                    {card.span2 && <span>{card.span2}</span>}
                    {card.span3 && <span className="pb-sub">{card.span3}</span>}
                  </div>

                  <div className="elementor-element elementor-align-center elementor-widget elementor-widget-button">
                    <div className="elementor-widget-container">
                      <div className="elementor-button-wrapper" style={{marginTop:'20px'}}>
                        <a
                          className="elementor-button elementor-size-lg"
                          onClick={handleBookSession} // Trigger the navigation on click
                          style={{ cursor: "pointer" }} // Ensure it looks clickable
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="elementor-button-content-wrapper">
                            <span className="elementor-button-text">
                              Book a Session
                            </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              {/* )} */}
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          {card.description === "African Safari (Kenya)" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                A livestream from the Ol Kenyei camp in the Masai Mara region,
                showing wild animals in their own habitat, with knowledgeable
                guides talking about the ecosystem of the region and answering
                questions. Content can be tailored to very young children in
                Early Learning Centres, to Primary school students.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                The session is designed to be run over one period within the
                classroom. It is delivered as an online meeting (e.g via Zoom or
                Teams) into which students are ideally logged in individually on
                their laptops so that they can readily interact with the vyatra
                guides during livestreams, as well as with peers from a school
                in a town located by the Ganga river who will also be in the
                session.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                We provide an on-screen vyatra lead who coordinates the live
                crosses with the ‘on-the-ground’ tourist guides.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our{" "}
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "Ganga River Ecosystem, India"   ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Morphology of the Ganga river, the flora and fauna supported by
                the river, its significance for humans, and imapct upon it by
                human activity. Featuring livestreams from Rishikesh and
                Varanasi. Interactive participation by Year 7 peers from a city
                located by the Ganga river.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                We provide a presentation slidedeck for the teacher, along with
                suggested speaker notes.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                The session is designed to be run over one period within the
                classroom. It is delivered as an online meeting (e.g via Zoom or
                Teams) into which students are ideally logged in individually on
                their laptops so that they can readily interact with the vyatra
                guides during livestreams, as well as interact with peers from a
                school in a town located by the Ganga river who will also be in
                the session.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                We provide an on-screen vyatra lead who coordinates the live
                crosses with the ‘on-the-ground’ tourist guides.{" "}
              </p>

              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Year 7 – Ganga River Ecosystem
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() =>
                    window.open("/Year-7-Ganga-River-Ecosystem.pdf", "_blank")
                  }
                >
                  {" "}
                  Slidedeck
                </span>
              </p>

              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Year 7 – Ganga River Ecosystem
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "/Year-7-Ganga-River-Ecosystem-Speaker-Notes.pdf",
                      "_blank"
                    )
                  }
                >
                  {" "}
                  Speaker Notes{" "}
                </span>
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) :  card.description === "The Ganga River Ecosystem, India"? (
<Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Morphology of the Ganga river, the flora and fauna supported by
                the river, its significance for humans, and imapct upon it by
                human activity. Featuring livestreams from Rishikesh and
                Varanasi. Interactive participation by Year 7 peers from a city
                located by the Ganga river.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                We provide a presentation slidedeck for the teacher, along with
                suggested speaker notes.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                The session is designed to be run over one period within the
                classroom. It is delivered as an online meeting (e.g via Zoom or
                Teams) into which students are ideally logged in individually on
                their laptops so that they can readily interact with the vyatra
                guides during livestreams, as well as interact with peers from a
                school in a town located by the Ganga river who will also be in
                the session.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                We provide an on-screen vyatra lead who coordinates the live
                crosses with the ‘on-the-ground’ tourist guides.{" "}
              </p>

              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Year 7 – Ganga River Ecosystem
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() =>
                    window.open("/Year-7-Ganga-River-Ecosystem.pdf", "_blank")
                  }
                >
                  {" "}
                  Slidedeck
                </span>
              </p>

              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Year 7 – Ganga River Ecosystem
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "/Year-7-Ganga-River-Ecosystem-Speaker-Notes.pdf",
                      "_blank"
                    )
                  }
                >
                  {" "}
                  Speaker Notes{" "}
                </span>
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ): card.description ===
            "The Bawaka Experience, Nhulunbuy, Northern Territory, Australia" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Exploration of the landforms and landscapes of the region, their
                formation, and how they interact with human activities.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                We provide a presentation slidedeck for the teacher, along with
                suggested speaker notes.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                {" "}
                The session is designed to be run over one period within the
                classroom. It is delivered as an online meeting (e.g via Zoom or
                Teams) into which students are ideally logged in individually on
                their laptops so that they can readily interact with the vyatra
                guides during livestreams, as well as interact with peers from a
                local school in the region.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                We provide an on-screen vyatra lead who coordinates the live
                crosses with the ‘on-the-ground’ tourist guides.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "The Silk Roads of Samarkand, Uzbekistan" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Examination of how people, goods, and information move and the
                implications for economies and cultures.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                We provide a presentation slidedeck for the teacher, along with
                suggested speaker notes.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                The session is designed to be run over one period within the
                classroom. It is delivered as an online meeting (e.g via Zoom or
                Teams) into which students are ideally logged in individually on
                their laptops so that they can readily interact with the vyatra
                guides during livestreams, as well as interact with peers from a
                school in Uzbekistan.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                We provide an on-screen vyatra lead who coordinates the live
                crosses with the ‘on-the-ground’ tourist guides.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "The Ganga River Ecosystem" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Opportunity to deliver a brief presentation of Indian places and
                culture to global peers, and interact with them live.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                This virtual journey is delivered to Year 7 students in
                Australia as part of their Geography, ‘Water in the World’ unit.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                For each session, we have a place for three students from India
                to deliver a brief presentation each about the signficance of
                the Ganga river in their lives, and answer questions from the
                Australian students.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "The Saint of Sabarmati" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Opportunity to deliver a brief presentation of Indian places and
                culture to global peers, and interact with them live.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                This virtual journey is delivered to Year 10 students in
                Australia as part of their History, ‘Rights & Freedoms’ unit.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                For each session, we have a place for three students from India
                to deliver a brief presentation each about the place of Mahatma
                Gandhi’s teachings in their lives, and answer questions from the
                Australian students.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "Mystical Hinduism" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Opportunity to deliver a brief presentation of Indian places and
                culture to global peers, and interact with them live.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                This virtual journey is delivered to Year 8 students in
                Australia as part of their Religious Education, ‘Hinduism’ unit.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                For each session, we have a place for four students from India
                to deliver a brief presentation each about a Hindu festival,
                e.g. Dashehra, Divali – why it is celebrated, and how they
                celebrate it in their family, and answer questions from the
                Australian students.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "In the Buddha’s Footsteps" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Opportunity to deliver a brief presentation of Indian places and
                culture to global peers, and interact with them live.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                This virtual journey is delivered to Year 7 students in
                Australia as part of their Religious Education, ‘Buddhism’ unit.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                For each session, we have a place for four students from India
                to deliver a brief presentation each about the role of the
                Buddha’s teachings in their lives, and answer questions from the
                Australian students.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Year 7 – Buddishm
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() =>
                    window.open("/Year-7-Buddishm-Speaker-Notes.pdf", "_blank")
                  }
                >
                  {" "}
                  Speaker Notes .
                </span>
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Year 7 – Buddishm
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() =>
                    window.open("/Year-7-Buddhism-Slidedeck.pdf", "_blank")
                  }
                >
                  {" "}
                  Slidedeck .
                </span>
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "Shogunate Japan" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Opportunity to deliver a brief presentation of Japanese places
                and culture to global peers, and interact with them live.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                This virtual journey is delivered to Year 8 students in
                Australia as part of their History, ‘The Asia Pacific World’
                unit.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                For each session, we have a place for three students from Japan
                to deliver a brief presentation about an aspect of the Shogunate
                era they find interesting, and answer questions from the
                Australian students.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "The Shogunate Japan" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
               Examination of Japan under the Shoguns – how they lived and governed.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
              We provide a presentation slidedeck for the teacher, along with suggested speaker notes.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
               The session is designed to be run over one period within the classroom. It is delivered as an online meeting (e.g via Zoom or Teams) into which students are ideally logged in individually on their laptops so that they can readily interact with the vyatra guides during livestreams, as well as interact with peers from a school in Japan.

              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             We provide an on-screen vyatra lead who coordinates the live crosses with the ‘on-the-ground’ tourist guides. Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>

          ) : card.description=== "Investigating the Ancient Past" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Opportunity to deliver a brief presentation about the Holy Land
                to global peers, and interact with them live.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                This virtual journey is delivered to Year 7 students in
                Australia as part of their History, ‘Investigating the Ancient
                Past’ unit.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                For each session, we have a place for three students from Israel
                to deliver a brief presentation about an aspect of Jerusalem’s
                history they find interesting, and answer questions from the
                Australian students.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "The Holy Land" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Opportunity to deliver a brief presentation about the Holy Land
                to global peers, and interact with them live.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                This virtual journey is delivered to Year 9 students in
                Australia as part of their RE, ‘Judaism’ & ‘Christianity’ units.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                For each session, we have a place for three students from Israel
                to deliver a brief presentation about an aspect of Jerusalem’s
                Religious places and rituals they find interesting, and answer
                questions from the Australian students.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "Environmental Change & Management" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Opportunity to deliver a brief presentation about the Masai Mara
                to global peers, and interact with them live.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                This virtual journey is delivered to Year 10 students in
                Australia as part of their Geography, ‘Environmental Change &
                Management’ unit.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                For each session, we have a place for three students from Kenya
                to deliver a brief presentation about the significance of the
                Masai Mara in their lives, and answer questions from the
                Australian students.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) : card.description === "The Silk Roads" ? (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Opportunity to deliver a brief presentation about the Silk Road
                history of Uzbekistan to global peers, and interact with them
                live.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                This virtual journey is delivered to Year 9 students in
                Australia as part of their ‘Geographies of Interconnections’
                unit.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                For each session, we have a place for three students from
                Uzbekistan to deliver a brief presentation about an aspect of
                the historical Silk Roads they find interesting, and answer
                questions from the Australian students.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
          ) :card.description === "In Buddha's Footsteps, India" ?
          (
            <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
               Opportunity to deliver a brief presentation of Indian places and culture to global peers, and interact with them live.

              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
               This virtual journey is delivered to Year 7 students in Australia as part of their Religious Education, ‘Buddhism’ unit.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
               For each session, we have a place for four students from India to deliver a brief presentation each about the role of the Buddha’s teachings in their lives, and answer questions from the Australian students.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
               Year 7 – Buddishm
                <span
               style={{ color: "#ff5722", cursor: "pointer" }}
               onClick={() =>
                 window.open("/Year-7-Buddishm-Speaker-Notes.pdf", "_blank")
               }
                >
                  {" "}
                  Speaker Notes .
                </span>
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Year 7 – Buddishm
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() =>
                    window.open("/Year-7-Buddhism-Slidedeck.pdf", "_blank")
                  }
                >
                  {" "}
                  Slidedeck .
                </span>
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
           ) : card.description === "Jersualem, Israel" ? (
            <Col md={7}>
            <h4
              style={{
                color: "#2B2C31",
                fontSize: "28px",
                fontFamily: "Poppins, Sans-serif",
                fontWeight: "600",
              }}
            >
              Overview
            </h4>
            <p
              style={{
                color: "#7A7E94",
                fontSize: "16px",
                fontFamily: "Poppins, Sans-serif",
                fontWeight: "400",
              }}
            >
             A study of how archaeological and written sources are brought together to understand the history associated with ancient sites.

            </p>
            <h4
              style={{
                color: "#2B2C31",
                fontSize: "28px",
                fontFamily: "Poppins, Sans-serif",
                fontWeight: "600",
              }}
            >
              Details
            </h4>
            <p
              style={{
                color: "#7A7E94",
                fontSize: "16px",
                fontFamily: "Poppins, Sans-serif",
                fontWeight: "400",
              }}
            >
            We provide a slidedeck for the teacher, along with suggested speaker notes.
            </p>
            <p
              style={{
                color: "#7A7E94",
                fontSize: "16px",
                fontFamily: "Poppins, Sans-serif",
                fontWeight: "400",
              }}
            >
           The session is designed to be run over one period within the classroom. It is delivered as an online meeting (e.g via Zoom or Teams) into which students are ideally logged in individually on their laptops so that they can readily interact with the vyatra guides during livestreams, as well as interact with peers from a local school in Israel.

            </p>
            <p  style={{
                color: "#7A7E94",
                fontSize: "16px",
                fontFamily: "Poppins, Sans-serif",
                fontWeight: "400",
              }}>We provide an on-screen vyatra lead who coordinates the live crosses with the ‘on-the-ground’ tourist guides.
            </p>
            <p
              style={{
                color: "#7A7E94",
                fontSize: "16px",
                fontFamily: "Poppins, Sans-serif",
                fontWeight: "400",
              }}
            >
           Year 7 – Ancient Jerusalem
              <span
             style={{ color: "#ff5722", cursor: "pointer" }}
             onClick={() =>
               window.open("/Year-7-Ancient-Jerusalem-1.pdf", "_blank")
             }
              >
                {" "}
                Slidedeck 
              </span>
            </p>
          
            <p
              style={{
                color: "#7A7E94",
                fontSize: "16px",
                fontFamily: "Poppins, Sans-serif",
                fontWeight: "400",
              }}
            >
              Your booking is subject to our
              <span
                style={{ color: "#ff5722", cursor: "pointer" }}
                onClick={() => navigate("/terms-and-conditions")}
              >
                {" "}
                terms & conditions
              </span>
            </p>
          </Col>
            ): card.description === "Mystical Hinduism, India" ? (
              <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
               An account of the key Hindu practices of Yoga, meditation & devotion, told with the spectacular backdrop of the river Ganges (Ganga) at Rishikesh in the Himalayas and then at Varanasi.
  
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
               Partake in the simple but moving “arghya” ritual – offering of Ganga jal (Ganga water) in gratitude to the rising sun in Rishikesh, followed by a demonstration of the sun salutation Yoga asanas.
              </p>
              <p style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}>
              See the live sights and hear the sounds of eternal Varanasi, with our local guides narrating absorbing stories of the city and its luminaries.
              </p>

              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
              We provide a presentation slidedeck for the teacher, along with suggested speaker notes.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
            The session is designed to be run over one period within the classroom. It is delivered as an online meeting (e.g via Zoom or Teams) into which students are ideally logged in individually on their laptops so that they can readily interact with the vyatra guides during livestreams, as well as interact with peers from a school in India.
  
              </p>
              <p  style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}>
                  We provide an on-screen vyatra lead who coordinates the live crosses with the ‘on-the-ground’ tourist guides.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             Year 8 – Hinduism
                <span
               style={{ color: "#ff5722", cursor: "pointer" }}
               onClick={() =>
                 window.open("/Year-8-Hinduism-Slidedeck.pdf", "_blank")
               }
                >
                  {" "}
                  Slidedeck 
                </span>
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
            Year 8 – Hinduism
                <span
               style={{ color: "#ff5722", cursor: "pointer" }}
               onClick={() =>
                 window.open("/Year-8-Hinduism-Speaker-Notes.pdf", "_blank")
               }
                >
                  {" "}
                  Speaker Notes.
                </span>
              </p>
            
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
            ): card.description === "The Silk Roads of Samarqand, Uzbekistan" ?(
              <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
              Examination of how people, goods, and information move and the implications for economies and cultures.
  
              </p>

              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             We provide a presentation slidedeck for the teacher, along with suggested speaker notes.

              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
           The session is designed to be run over one period within the classroom. It is delivered as an online meeting (e.g via Zoom or Teams) into which students are ideally logged in individually on their laptops so that they can readily interact with the vyatra guides during livestreams, as well as interact with peers from a school in Uzbekistan.
  
              </p>
              <p  style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}>
                 We provide an on-screen vyatra lead who coordinates the live crosses with the ‘on-the-ground’ tourist guides.
              </p>
             
             
            
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
            ) : card.description === "The Holy Land - Jerusalem, Israel" ? (
              <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             An immersive exploration of the significance of the Holy City for the Abrahamic religions. Includes:
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             1. Walking in the footsteps of the pilgrims from the time when the Second Temple stood 2,000 years ago
             
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             2. Seeing the Western (Wailing) Wall, where Jews from across the world come to pray today
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
            3. Viewing Har Habayit (the Temple Mount)/Haram al-Sharif (the Holy Esplanade), a holy site for both Judaism and Islam, and the spectacular golden Dome of the Rock
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
            4. Reliving Jesus’ crucifixion, death, burial and resurrection at the Church of the Holy Sepulchre
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
           All are UNESCO World Heritage sites.

              </p>

              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             We provide a presentation slidedeck for the teacher, along with suggested speaker notes.

              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
          The session is designed to be run over one period within the classroom. It is delivered as an online meeting (e.g via Zoom or Teams) into which students are ideally logged in individually on their laptops so that they can readily interact with the vyatra guides during livestreams, as well as interact with peersfrom a school in Israel.
  
              </p>
              <p  style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}>
                 We provide an on-screen vyatra lead who coordinates the live crosses with the ‘on-the-ground’ tourist guides.
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
               Year 9/10 RE Judaism, Christianity
                <span
                 style={{ color: "#ff5722", cursor: "pointer" }}
                 onClick={() =>
                   window.open("/Year 9 - The Holy Land.pdf", "_blank")
                 }
                >
                  {" "}
                  Slidedeck .
                </span>
              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
            ): card.description === "Indian Independence Movement: The Saint of Sabarmati" ?(
              <Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             Exploration of the global struggle for rights and freedoms, presented via the prism of (Mahatma) Mohandas Gandhi and the Indian independence movement
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             We provide a presentation slidedeck for the teacher, along with suggested speaker notes.

              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
          The session is designed to be run over one period within the classroom. It is delivered as an online meeting (e.g via Zoom or Teams) into which students are ideally logged in individually on their laptops so that they can readily interact with the vyatra guides during livestreams, as well as interact with peersfrom a school in India.
  
              </p>
              <p  style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}>
                 We provide an on-screen vyatra lead who coordinates the live crosses with the ‘on-the-ground’ tourist guides.
              </p>
            
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
            ) : card.description === "Ol Kinyei, Masai Mara, Kenya" ? (
<Col md={7}>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Overview
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             An overview of environmental changes and response strategies in the context of Ol Kinyeyi national park in Kenya.
              </p>
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "28px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                Details
              </h4>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
             We provide a presentation slidedeck for the teacher, along with suggested speaker notes.

              </p>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
          The session is designed to be run over one period within the classroom. It is delivered as an online meeting (e.g via Zoom or Teams) into which students are ideally logged in individually on their laptops so that they can readily interact with the vyatra guides during livestreams, as well as interact with peers from a school in Nairobi.
  
              </p>
              <p  style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}>
                 We provide an on-screen vyatra lead who coordinates the live crosses with the ‘on-the-ground’ tourist guides.
              </p>
            
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Your booking is subject to our
                <span
                  style={{ color: "#ff5722", cursor: "pointer" }}
                  onClick={() => navigate("/terms-and-conditions")}
                >
                  {" "}
                  terms & conditions
                </span>
              </p>
            </Col>
            ) : null}
          <Col md={5}>
            <Card
              style={{
                padding: "20px 0px 20px 50px",
                border: "1px solid #EEEFF4",
              }}
            >
              <h4
                style={{
                  color: "#2B2C31",
                  fontSize: "14px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "700",
                }}
              >
                SUMMARY
              </h4>
              <h2
                style={{
                  color: "#2B2C31",
                  fontSize: "48px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "600",
                }}
              >
                45 - 60
              </h2>
              <p
                style={{
                  color: "#7A7E94",
                  fontSize: "16px",
                  fontFamily: "Poppins, Sans-serif",
                  fontWeight: "400",
                }}
              >
                Mins
              </p>
            </Card>
          </Col>
        </Row>
      </Container>
      <div className="accordion-container">
        <Accordion defaultActiveKey="0">
          <p
            style={{
              color: "black",
              textAlign: "center",
              fontSize: "28px",
              fontWeight: "600",
              textDecoration: "none",
              lineHeight: "35px",
            }}
          >
            Frequently Asked Questions
          </p>
          <Card>
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordian-header">
                How do these vyatra journeys work for schools?
              </Accordion.Header>
              <Accordion.Body>
                {card.description === "African Safari (Kenya)" ||
                card.description === "Ganga River Ecosystem, India" ||
                card.description ===
                  "The Bawaka Experience, Nhulunbuy, Northern Territory, Australia" ||
                card.description ===
                  "The Silk Roads of Samarkand, Uzbekistan"  || card.description === "The Silk Roads of Samarqand, Uzbekistan" || card.description === "The Ganga River Ecosystem, India" ?(
                  <>
                    <p>
                      We typically have students joining these sessions from
                      their classrooms, experiencing the live journeys on the
                      electronic whiteboard screen in their classroom or
                      individually on their own laptops.
                    </p>
                    <p>
                      We have 2-3 of our guides in key locations in the
                      destination country plus 3-4 students from a local school
                      also joining in the meeting.
                    </p>
                    <p>
                      We encourage students to ask questions of the guides as
                      well as their local student peers, which they can either
                      do by speaking directly into the meeting, or posting on
                      chat. We are also happy to have multiple classrooms from
                      the same Year level joining in the sessions together.
                    </p>
                  </>
                ) : (
                  <p>
                    We typically have students joining these sessions from their
                    classrooms, experiencing the live journeys on the electronic
                    whiteboard screen in their classroom. We can however, also
                    accommodate students joining individually on their own
                    laptops. We encourage students to ask questions, which they
                    can either do by speaking into the microphone of the laptop
                    running our Zoom meeting, or posting it on chat.
                    Alternatively the questions and comments can be directed to
                    the teacher, who can then ask/post it in the Zoom meeting.
                    We are also happy to have multiple classrooms from the same
                    Year level joining in the sessions together, although that
                    takes away the opportunity for each student to ask their own
                    questions of our expert guides.
                  </p>
                )}
              </Accordion.Body>
            </Accordion.Item>
            {card.description !== "Ganga River Ecosystem, India" &&
  card.description !== "In the Buddha’s Footsteps" &&
  card.description !== "Jerusalem, Israel" &&
  card.description !== "Mystical Hinduism, India" &&
  card.description !== "The Holy Land - Jerusalem, Israel" &&
  card.description !== "In Buddha's Footsteps, India" && card.description !== "The Ganga River Ecosystem, India"  ? (
    <Accordion.Item eventKey="1">
      <Accordion.Header>What exactly do you show?</Accordion.Header>
      <Accordion.Body>
        {card.description ===
        "The Bawaka Experience, Nhulunbuy, Northern Territory, Australia" ? (
          <p>
            Exploration of the landforms and landscapes of the region,
            their formation, and how they interact with human
            activities.
          </p>
        ) : card.description === "The Silk Roads of Samarkand, Uzbekistan" ? (
          <p>
            Examination of how people, goods, and information move and
            the implications for economies and cultures.
          </p>
        ) : card.description === "The Shogunate Japan" ? (
          <p>
            Examination of Japan under the Shoguns - how they lived and governed.
          </p>
        ) : card.description === "The Silk Roads of Samarqand, Uzbekistan" ? (
          <p>
            Examination of how people, goods, and information move and the implications for economies and cultures.
          </p>
        ) : card.description === "Ol Kinyei, Masai Mara, Kenya" ? (
          <>
            <p>
              An overview of environmental changes affecting Ol Kinyeyi
              national park in Kenya, and the response strategies put
              in place by its custodians.
            </p>
            <p>
              These sessions are presented by professional Guides from
              GameWatchers Kenya livestreaming from the National Park.
            </p>
            <p>
              In a typical session students will be able to see Zebras,
              Gazelles, Wilderbeast and Ostriches live, in their natural
              habitat. Obviously each session will be quite different
              depending on the weather and animals around - but that is
              the beauty of the experience - it is live and unfiltered,
              and the animals are always just there! Some of our guides
              are Masai men and women, all wearing their bright
              traditional clothes, and so seeing them is a visual
              spectacle in itself.
            </p>
          </>
        ) : card.description ===
          "Indian Independence Movement: The Saint of Sabarmati" ? (
          <p>
            Exploration of the global struggle for rights and freedoms,
            presented via the prism of (Mahatma) Mohandas Gandhi and the
            Indian independence movement.
          </p>
        ) : (
          <p>
            These sessions feature professional Guides from GameWatchers Kenya
            livestreaming from the National Park. In a typical session students
            will be able to see Zebras, Gazelles, Wilderbeast and Ostriches live,
            in their natural habitat. Obviously each session will be quite different
            depending on the weather and animals around - but that is the beauty
            of the experience - it is live and unfiltered, and the animals are
            always just there! Some of our guides are Masai men and women, all
            wearing their bright traditional clothes, and so seeing them is a
            visual spectacle in itself.
          </p>
        )}
      </Accordion.Body>
    </Accordion.Item>
  ) : null}


            <Accordion.Item eventKey="2">
              <Accordion.Header>
                We would like to show a place that you are not currently
                covering. How can this be done?
              </Accordion.Header>
              <Accordion.Body>
                {card.description === "African Safari (Kenya)" ? (
                  <p>
                    Simply let us know of your ideas by writing into{" "}
                    <span style={{ color: "#ff5722" }}>rakesh@vyatra.com</span>{" "}
                    . We have an extensive reach into tourist guides around the
                    world, and should be reasonably able to accommodate your
                    request.
                  </p>
                ) : (
                  <p>
                    {" "}
                    Simply let us know of your ideas by writing into{" "}
                    <span style={{ color: "#ff5722" }}>
                      support@vyatra.com
                    </span>{" "}
                    . We have an extensive reach into tourist guides around the
                    world, and should be reasonably able to accommodate your
                    request.
                  </p>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        </Accordion>
      </div>
      <TestimonialPage />
      <Emailus />
    </>
  );
};

export default CardDetailPage;
