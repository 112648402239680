import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import BookSession from './BookSession';

const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const BookSessionForm = (props) => {
  const stripePromise = loadStripe('pk_test_51QCJUxHMKDTIncDZDA3oOxsThAdz6CKk5SaZDnTe9E5ZcnuywYfBckUX2T9u4m5hyIzGCwwVzpJbxIzsDhyRpAHK00lPSPYKQd');
  // process.env.VITE_STRIPE_PK is the publishable key you can either directly paste your stripe key here but not recommend if you are planning to upload the code on github as it should remain only available to you or save the key in .env file
  
  return (
    <div className='flex container mt-8'>
      <Elements stripe={stripePromise} options={options}>
        <BookSession />
      </Elements>
    </div>
  );
};

export default BookSessionForm;
